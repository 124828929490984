/* NO ANIMATION */
@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}

/* BOUNCE */
@mixin bounce($duration: 0.5s, $delay: 0s, $repeat: 1) {
  -webkit-animation: bounce $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: bounce $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}

/* WOBBLE */
@mixin wobble($duration: 0.5s, $delay: 0s, $repeat: 1) {
  -webkit-animation: wobble $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: wobble $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);}
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);}
  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);}
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}
@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);}
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);}
  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);}
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}

/* SHAKE */
@mixin shake($duration: 0.1s, $delay: 0s, $repeat: 10) {
  -webkit-animation: shake $duration ease-in-out $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: shake $duration ease-in-out $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  100% {@include translate(0, 0);}
}
@keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  0%, 100% {@include translate(0, 0);}
}



/* BOUNCE IMAGE */
@mixin imageGrowIn($duration: 1s, $delay: 0s, $repeat: 1) {
  -webkit-animation: imageGrowIn $duration ease-in-out $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: imageGrowIn $duration ease-in-out $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes imageGrowIn {
	from {
    background-size: 0% 0%, 0% 0%;
  }
	30% {
    background-size: 2.5em 2.5em, 5em 5em;
  }
	70% {
    background-size: 2.5em 2.5em, 3em 3em;
  }
	to {
    background-size: 1.9em 1.9em, 0% 0%;
  }
}
@keyframes imageGrowIn {
	from {
    background-size: 0% 0%, 0% 0%;
  }
	30% {
    background-size: 2.5em 2.5em, 5em 5em;
  }
	50% {
    background-size: 1.9em 1.9em, 3em 3em;
  }
	70% {
    background-size: 2.5em 2.5em, 5em 5em;
  }
	to {
    background-size: 1.9em 1.9em, 0% 0%;
  }
}

/* fadeOut */
@mixin fadeOut($duration: 0.5s, $delay: 0s, $repeat: 1) {
  -webkit-animation: fadeOut $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: fadeOut $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

/* BACKGROUND BLINK */
@mixin backgroundBlink($duration: 1s, $delay: 0s, $repeat: 1) {
  -webkit-animation: backgroundBlink $duration ease-in-out $delay $repeat;
  animation: backgroundBlink $duration ease-in-out $delay $repeat;
}
@-webkit-keyframes backgroundBlink {
	0% {
    background-color: #C6DFED;
  }
	33% {
    background-color: #ffffff;
  }
	66% {
    background-color: #C6DFED;
  }
  100% {
    background-color: #ffffff;
  }
}
@keyframes backgroundBlink {
	0% {
    background-color: #C6DFED;
  }
	33% {
    background-color: #ffffff;
  }
	66% {
    background-color: #C6DFED;
  }
  100% {
    background-color: #ffffff;
  }
}


@mixin backgroundBlink2($duration: 1s, $delay: 0s, $repeat: 1) {
  -webkit-animation: backgroundBlink2 $duration ease-in-out $delay $repeat;
  animation: backgroundBlink2 $duration ease-in-out $delay $repeat;
}
@-webkit-keyframes backgroundBlink2 {
	0% {
    background-color: #F6ACBB;
  }
	33% {
    background-color: #ffffff;
  }
	66% {
    background-color: #F6ACBB;
  }
  100% {
    background-color: #ffffff;
  }
}
@keyframes backgroundBlink2 {
	0% {
    background-color: #F6ACBB;
  }
	33% {
    background-color: #ffffff;
  }
	66% {
    background-color: #F6ACBB;
  }
  100% {
    background-color: #ffffff;
  }
}