@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	width: 100%;
	height: 100%;
	overflow: auto;

	.App-wrap {
		position: relative;
		width: 100%;
		height: 60em;
		&.wide {
			margin: auto;
			width: calc(1em * 106.67); // 1920 / 18 = 106.67
		}
		.App-toggleCookiePopup {
			position: absolute;
			left: 2em;
			bottom: 2em;
			width: 3em;
			height: 3em;
			background-image: url('../assets/images/icons/icon-cookie.svg');
			cursor: pointer;
		}
	}
}