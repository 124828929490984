@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.LoadingPage {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/backgrounds/background-splash-characters.png'), url('../../assets/images/backgrounds/background-splash-shop.png');
	background-size: cover, cover;
	background-position: center center, center center;
	@include flex('center', 'center');
	.LoadingPage-logo {
		position: absolute;
		top: 4em;
		@include flex('center', 'center');
	}
	.LoadingPage-subtitle {
		position: absolute;
		top: 11.25em;
		width: 20em;
		text-align: center;
		line-height: 1.08;
		margin-left: 5em;
		span {
			font-size: 1.44em;
			font-weight: 800;
			color: #E1F1F9;
			text-transform: uppercase;
		}
	}
	.LoadingPage-text {
		position: absolute;
		top: 17em;
		margin-left: 5em;
		text-align: center;
		span {
			font-size: 1.33em;
			font-weight: 800;
		}
	}
}
