@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaStats {
	width: 21.78em;
	background-image: linear-gradient(#47B5F0, #207FB2);
	border-radius: 0.78em;
	border: 0.1em solid $blueDarkest;
	border-radius: 0.78em;
	@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
	@include flex('center', 'flex-start');
	padding: 0 0em 0.61em 0.28em;
	.AreaStats-content {
		width: 100%;
		border-radius: 0.78em;
		background-image: radial-gradient(#7DCCF7, $blue);
		border: 0.15em solid #7DCCF7;
		border-radius: 0.54em;
		padding: 0.44em;
		text-align: left;

		.AreaStats-title {
			padding-left: 0.56em;
			span {
				font-size: 1.44em;
				font-weight: 800;
				color: $white;
			}
		}

		.AreaStats-group {
			background-color: $blueLightest;
			border-radius: 0.44em;
			margin: 0.44em 0;
			padding: 0.67em;
			.AreaStats-stat {
				@include flex('space-between', 'center');
				margin-bottom: 0.33em;

				&.range {
					@include flex('', 'flex-start', 'column');
				}

				.AreaStats-statLabel {
					width: 100%;
					
	
					

					span {
						font-weight: 800;
						color: $blueDark;
					}
				}
				.AreaStats-statValue {
					width: 11.5em;
					height: 1.78em;
					
					&.range {
						width: 18.8em;
					}
				}

				&.dictionaryEntry {
					.AreaStats-statLabel {
						background-image: url(../../../assets/images/icons/icon-info.svg);
						background-size: 0.8em, 0.8em;
						background-position: right 0.4em bottom calc(50%);
						cursor: pointer;
						&:hover {
							background-color: $white;
							border-radius: 0.3em;
						}
					}
				}
			}
		}
	}
}