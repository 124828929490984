@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.DictionaryPopup {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba($blueDarkest, 0.5);
	// z-index: 3;
	text-align: left;
	@include flex('center', 'center');

	.DictionaryPopup-dialogue {
		background-color: $blueLightest;
		width: 56em;
		height: 40.5em;
		border-radius: 0.7em;
		border-color: $blue;
		border-style: solid;
		border-width: 0.3em;
		@include flex('','','column');
		@include no-select();

		.DicitionaryPopup-header {
			@include flex('space-between');
			margin: 0.9em;

			.DictionaryPopup-infoIcon {
				background-image: url(../../../assets/images/icons/icon-info.svg);
				background-size: contain;
				width: 2.44em;
				height: 2.44em;
				margin-left: 1.7em;
			}
			.DictionaryPopup-closeButton {
				background-image: url(../../../assets/images/icons/icon-close.svg);
				background-size: contain;
				width: 2.33em;
				height: 2.33em;
				@include transition('transform', 0.15s, 'ease');
				cursor: pointer;

				&:hover {
					@include scale(1.2);
				}
			}
		}

		.DictionaryPopup-list {
			margin-left: 2em;
			margin-bottom: 1.5em;
			margin-right: 1.6em;
			@include custom-scrollbar(0.67em, rgba($blueDark, 0.5), $blueDark);
			overflow-y: auto;

			.DictionaryPopup-listPoint {
				background-color: rgba($white, 0.5);
				border-radius: 0.55em;
				margin-bottom: 0.66em;
				padding: 1em;
				width: 50em;

				&.isSelected {
					background-color: $white;
				}

				.DictionaryPopup-title {

					span {
						color: $blueDark;
						font-size: 1.44em;
						font-weight: bolder;
					}
				}
				.DictionaryPopup-description {

					p {
						margin: 0;
						color: $blueDark;
						font-size: 1.33em;
						font-weight: bold;

						span.plus {
							display: inline-block;
							margin: 0 0.25em;
							width: 1em;
							height: 1em;
							background-image: url('../../../assets/images/icons/icon-plus.svg');
						}
					}
				}
			}
		}
	}
}