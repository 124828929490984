@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaActionOptionSelect {
	cursor: pointer;
	position: relative;
	width: 54em;
	height: 8.33em;
	border-radius: 1em;
	padding: 0.33em;
	background-color: $blue;
	margin-bottom: 1em;
	@include opacity(0.75);
	@include flex('space-between', 'center');

	&:hover {
		@include opacity(1);
	}
	
	.AreaActionOptionSelect-content {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 0.78em;
		padding: 0.44em;
		background-color: $blueLightest;
		@include flex('flex-start', 'flex-center');
		.AreaActionOptionSelect-optionStatus {
			position: absolute;
			@include flex('center', 'center');
			top: 0.5em;
			right: 0.5em;
			width: 1.78em;
			height: 1.78em;
			border-radius: 100%;
			background-color: #C6DFED;
			@include box-shadow-inset-outset(
				0, 0.28em, 0.11em, 0, rgba(black, 0.16),
				0, 0.11em, 0, 0, #ffffff
			);

			.AreaActionOptionSelect-checkMark {
				position: absolute;
				left: calc(50% - 5em / 2 + 0.1em);
				width: 5em;
				height: 5em;
				// z-index: 3;

				&.confirmed {
					@include imageGrowIn();
				}
				&.unconfirmed {
					@include transition('opacity', 0.25s, ease);
					left: calc(50% - 1.9em / 2 + 0.1em);
					width: 1.9em;
					height: 1.9em;
					@include opacity(0);
				}
				background-image: url('../../../assets/images/icons/icon-check.svg'), radial-gradient(50% 50%, rgba(white, 1) 30%, rgba(white, 0.75), rgba(white, 0));
			}
		}
		.AreaActionOptionSelect-optionImage {
			width: 15.56em;
			height: 100%;
			border-radius: 0.33em;
			background-color: #C6DFED;
			background-size: auto 5em;
			margin-right: 1.11em;
			&.marketing {
				background-image: url('../../../assets/images/actions/marketing.svg');
				&.marketing-a {background-image: url('../../../assets/images/action-options/marketing-a.svg');}
				&.marketing-b {background-image: url('../../../assets/images/action-options/marketing-b.svg');}
				&.marketing-c {background-image: url('../../../assets/images/action-options/marketing-c.svg');}
			}
			&.shop {
				background-image: url('../../../assets/images/actions/shop-interior-design.svg');
				&.shop-b,
				&.shop-c {
					background-image: url('../../../assets/images/actions/staff-1.svg');
				}
			}
			&.offer-campaign {
				background-image: url('../../../assets/images/actions/offer-campaign.svg');
				&.offer-campaign-a {
					background-image: url('../../../assets/images/action-options/offer-campaign-a.svg');
				}
				&.offer-campaign-b,
				&.offer-campaign-c {
					background-image: url('../../../assets/images/action-options/offer-campaign-b-c.svg');
				}
			}
			&.hire-helper-production {
				background-image: url('../../../assets/images/actions/staff-2.svg');
			}
			&.tshirt-supplier {
				background-image: url('../../../assets/images/actions/tshirt-supplier.svg');
			}
			&.buy-hardware {
				background-image: url('../../../assets/images/actions/hardware.svg');
			}
			&.new-designs {
				background-image: url('../../../assets/images/actions/tshirt-designs.svg');
			}
			&.financing {
				background-image: url('../../../assets/images/actions/financing.svg');
			}
			&.innovation {
				background-image: url('../../../assets/images/actions/innovation.svg');
			}
			&.upskilling {
				background-image: url('../../../assets/images/actions/upskilling.svg');
			}

		}
		.AreaActionOptionSelect-info {
			width: 33.33em;
			height: 100%;
			color: $blueDark;
			@include flex('center', 'flex-start', 'column');
			@include no-select();
			
			.AreaActionOptionSelect-optionTitle {
				span {
					font-size: 1.44em;
					font-weight: 800;
				}
			}
			.AreaActionOptionSelect-optionDescription {
				p {
					margin: 0;
					font-size: 1.33em;
					font-weight: 700;
					line-height: 1.1;
				}
			}
		}
		.AreaActionOptionSelect-select {
			position: absolute;
			top: 0.5em;
			right: 3em;
			width: 9.28em;
			height: 2.56em;
		}
		.AreaActionOptionSelect-label {
			position: absolute;
			bottom: 0.5em;
			right: 1em;
			span {
				color: $blueDark;
			}
		}
	}

	&.selected {
		cursor: pointer;
		background-color: #E8B300;
		@include opacity(1);
		.AreaActionOptionSelects-content {
			background-color: white;
			.AreaActionOptionSelects-optionStatus {
				background-color: white;
			}
			.AreaActionOptionSelects-optionImage {
				background-color: $blueLightest;
			}
		}
	}
}