@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Select {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 0.33em;
	background-color: #C6DFED;
	color: $blueDark;
	text-align: right;
	border: 0.11em solid $blue;
	cursor: pointer;

	&.open {
		border-radius: 0.33em 0.33em 0 0;
		
		.Select-dropdown {
			height: auto;
			border: 0.11em solid $blue;
		}
	}
	&.disabled {
		cursor: default;
	}
	
	.Select-selected {
		position: relative;
		height: 100%;		
		text-align: right;
		padding-right: 2em;
		span {
			font-size: 1.44em;
			font-weight: 800;
		}
		&:after {
			content: '';
			position: absolute;
			border-top-right-radius: 0.33em;
			border-bottom-right-radius: 0.33em;
			right: -0.11em;
			top: -0.11em;
			height: calc(100% + 0.22em);
			width: 1.78em;
			background-color: $blue;
			background-image: url('../../../assets/images/icons/icon-arrow-down.svg');
			background-size: 1em auto;
		}
	}

	.Select-dropdown {
		position: absolute;
		left: -0.11em;
		top: -0.11em;
		width: calc(100% + 0.22em);
		height: 0;
		max-height: 15em;
		background-color: white;
		border-radius: 0.33em;
		overflow: hidden;
		overflow-y: auto;
		z-index: 2;
		@include custom-scrollbar(0.37em, #C6DFED, $blue);
		@include transition('height', 0.3s, 'ease');
		
		.Select-option {
			cursor: pointer;
			position: relative;			
			padding: 0 0.5em;
			height: 1.78em;
			text-align: center;
			span {
				font-size: 1.44em;
				font-weight: 800;
			}
			&:hover {
				color: white;
				background-color: $blue;
			}
			&.disabled {
				cursor: not-allowed;
				opacity: 0.5;
				&:hover {
					background-color: white;
				}
			}
			&:first-of-type {
				border-top-left-radius: 0.33em;
				border-top-right-radius: 0.33em; 
			}
			&:last-of-type {
				border-bottom-left-radius: 0.33em;
				border-bottom-right-radius: 0.33em; 
			}

			&.selected {
				background-color: #C6DFED;
				&:hover {
					color: white;
					background-color: $blue;
				}
			}
		}
	}
}