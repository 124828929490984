@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Logo {
	width: 34em;
	height: 8em;
	margin-left: 3.5em;
	background-image: url('../../../assets/images/logo.svg');
	&.game {
		margin-left: 0.5em;
		width: 25em;
		height: 5.84em;
	}
}