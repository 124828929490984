/* Colors */
$blueLightest: #E1F1F9;
$blue: #46B4EF;
$blueDark: #265684;
$blueDarkest: #1B1F24;

$white: #fff;
$black: #000;

$orange: #F2BD2B;
$orangeDark: #CB9D22;

$brown: #8A6A14;