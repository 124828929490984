@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.AreaPageLastQuarter {
	position: relative;
	width: 100%;
	height: 100%;
	pointer-events: all;
	@include flex('center', 'center');
	.AreaPageLastQuarter-predictedBudget {
		margin-right: 2.78em;
	}
}