@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaPagesBtn {
	width: 11.11em;
	height: 11.11em;
	background-image: linear-gradient(#8BA1AD, #596D77);
	border-radius: 0.78em;
	border: 0.1em solid #1E1E20;
	border-radius: 0.78em;
	padding: 0 0.28em 0.44em 0.28em;
	margin: 0 1.11em;
	cursor: pointer;
	@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
	@include flex('center', 'flex-start');

	.AreaPagesBtn-content {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 0.78em;
		background-image: radial-gradient(#ADBDC6, #8BA1AD);
		border: 0.22em solid #ADBDC6;
		border-radius: 0.54em;
		padding: 0.44em;
		text-align: left;

		.AreaPagesBtn-title {
			text-align: center;
			white-space: nowrap;
			span {
				color: white;
				font-size: 1.44em;
				font-weight: 800;
			}
		}
		.AreaPagesBtn-imageWrapper {
			position: absolute;
			left: 0.44em;
			right: 0.44em;
			bottom: 0.44em;
			height: 7em;
			border-bottom-left-radius: 0.78em;
			border-bottom-right-radius: 0.78em;
			background-color: $blueLightest;
			@include box-shadow-inset(0, -0.2em, 0.3em, 0.2em, #C6DFED);
			.AreaPagesBtn-image {
				background-size: auto 5em;
				width: 100%;
				height: 100%;
				@include opacity(0.5);
				&.office {
					background-image: url('../../../assets/images/icons/icon-office.svg');
				}
			}
		}
	}

	&:hover {
		background-image: linear-gradient(darken(#8BA1AD, 3%), darken(#596D77, 3%));
		.AreaPagesBtn-content {
			background-image: radial-gradient(darken(#ADBDC6, 3%), darken(#8BA1AD, 3%));
			border-color:darken(#ADBDC6, 3%);
		}
	}

	&.active {
		background-image: linear-gradient(#47B5F0, #207FB2);
		border-color: $blueDarkest;
		.AreaPagesBtn-content { 
			background-image: radial-gradient(#7DCCF7, $blue);
			border-color: #7DCCF7;
		}

		&:hover {
			background-image: linear-gradient(darken(#47B5F0, 3%), darken(#207FB2, 3%));
			.AreaPagesBtn-content {
				background-image: radial-gradient(darken(#7DCCF7, 3%), darken($blue, 3%));
				border-color:darken(#7DCCF7, 3%);
			}
		}

		.AreaPagesBtn-imageWrapper {
			.AreaPagesBtn-image {
				@include opacity(1);
			}
		}
		
	}
}