@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaActions {
	width: 100%;
	@include flex('center', 'center');
	pointer-events: none;
	.AreaActions-action {
		width: 18.22em;
		height: 11.11em;
		background-image: linear-gradient(#8BA1AD, #596D77);
		border-radius: 0.78em;
		border: 0.1em solid #1E1E20;
		border-radius: 0.78em;
		padding: 0 0.28em 0.44em 0.28em;
		margin: 0 1.11em;
		cursor: pointer;
		pointer-events: all;
		@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
		@include flex('center', 'flex-start');
		
		.AreaActions-actionContent {
			position: relative;
			width: 100%;
			height: 100%;
			border-radius: 0.78em;
			background-image: radial-gradient(#ADBDC6, #8BA1AD);
			border: 0.22em solid #ADBDC6;
			border-radius: 0.54em;
			padding: 0.2em 0.44em 0.44em 0.44em;
			text-align: left;

			.AreaAction-actionTitle {
				text-align: center;
				white-space: nowrap;
				span {
					color: white;
					font-size: 1.44em;
					font-weight: 800;
				}
			}
			.AreaAction-actionStatus {
				position: absolute;
				@include flex('center', 'center');
				top: 0.35em;
				right: 0.44em;
				width: 1.78em;
				height: 1.78em;
				border-radius: 100%;
				background-color: #6D808B;
				@include box-shadow-inset-outset(
					0, 0.28em, 0.11em, 0, rgba(black, 0.16),
					0, 0.11em, 0, 0, #ADBDC6
				);

				.AreaActions-checkMark {
					position: absolute;
					left: calc(50% - 5em / 2 + 0.1em);
					width: 5em;
					height: 5em;
					// z-index: 3;

					&.selected {
						@include imageGrowIn();
					}
					&.unselected {
						@include transition('opacity', 0.25s, ease);
						left: calc(50% - 1.9em / 2 + 0.1em);
						width: 1.9em;
						height: 1.9em;
						@include opacity(0);
					}
					background-image: url('../../../assets/images/icons/icon-check.svg'), radial-gradient(50% 50%, rgba(white, 1) 30%, rgba(white, 0.75), rgba(white, 0));
				}
			}
			.AreaAction-actionImageWrapper {
				position: absolute;
				border-bottom-left-radius: 0.78em;
				border-bottom-right-radius: 0.78em;
				background-color: $blueLightest;
				left: 0.3em;
				right: 0.3em;
				bottom: 0.3em;
				height: 7.25em;
				@include box-shadow-inset(0, -0.2em, 0.3em, 0.2em, #C6DFED);
				.AreaAction-actionImage {
					background-size: auto 5em;
					width: 100%;
					height: 100%;
					@include opacity(0.5);
					&.marketing {
						background-size: auto 6.94em;
						background-image: url('../../../assets/images/actions/marketing.svg');
					}
					&.shop {
						background-size: auto 6.43em;
						background-image: url('../../../assets/images/actions/shop-interior-design.svg');
					}
					&.offer-campaign {
						background-size: auto 6.46em;
						background-image: url('../../../assets/images/actions/offer-campaign.svg');
					}
					&.hire-helper-production {
						background-size: auto 6.47em;
						background-image: url('../../../assets/images/actions/staff-2.svg');
					}
					&.tshirt-supplier {
						background-image: url('../../../assets/images/actions/tshirt-supplier.svg');
					}
					&.buy-hardware {
						background-image: url('../../../assets/images/actions/hardware.svg');
					}
					&.new-designs {
						background-size: auto 6.79em;
						background-image: url('../../../assets/images/actions/tshirt-designs.svg');
					}
					&.financing {
						background-image: url('../../../assets/images/actions/financing.svg');
					}
					&.innovation {
						background-image: url('../../../assets/images/actions/innovation.svg');
					}
					&.upskilling {
						background-image: url('../../../assets/images/actions/upskilling.svg');
					}
				}
			}
		}

		&.open {
			background-image: linear-gradient(#47B5F0, #207FB2);
			border: 0.1em solid $blueDarkest;
			.AreaActions-actionContent { 
				background-image: radial-gradient(#7DCCF7, $blue);
				border: 0.22em solid #7DCCF7;
			}
			.AreaAction-actionStatus { 
				background-color: #2F94CA;
				@include box-shadow-inset-outset(
					0, 0.28em, 0.11em, 0, rgba(black, 0.16),
					0, 0.11em, 0, 0, #7DCCF7
				);
			}
			.AreaAction-actionImageWrapper {
				background-color: white;
				.AreaAction-actionImage {
					@include opacity(1);
				}
			}
		}

		&.disabled {
			cursor: not-allowed;
		}
	}
}