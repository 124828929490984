@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Game {
	position: relative;
	height: 100%;
	text-align: center;
	z-index: 1;

	&.enterShop {
		.Game-interface {
			@include opacity(0);
			@include fadeIn(0.5s, $timing: 'ease', $delay: 2.9s);
		}
	}

	.Game-interface {
		position: relative;
		padding: 1.5em 2em;
		width: 100%;
		height: 100%;		
		pointer-events: none;
		> div {
			pointer-events: all;
		}

		.Game-dictionaryButton {
			position: absolute;
			top: 1.33em;
			left: 1em;
			background-image: url(../../assets/images/icons/icon-info-button.svg);
			background-size: contain;
			height: 3.33em;
			width: 3.33em;
			cursor: pointer;
			&.popupIsOpen,
			&:hover {
				background-image: url(../../assets/images/icons/icon-info-button1.svg);
			}
		}

		.Game-resetBtn {
			position: absolute;
			bottom: 1em;
			left: 1em;
			width: 8em;
		}

		.Game-logo {
			position: relative;
			display: inline-block;
		}

		.Game-roundNumber {
			position: absolute;
			left: 1em;
		}
		.Game-navigation {
			position: relative;
			// margin-top: 1em;
			pointer-events: none;
			@include flex('center', 'center');
		}
		.Game-areaStats {
			position: absolute;
			left: 1em;
			margin-top: 2em;
		}
		.Game-areaPagesBtn {
			position: absolute;
			left: 3em;
			margin-top: 2em;
		}

		.Game-budget {
			position: absolute;
			bottom: 22.5em;
			right: 1em;
			// margin-top: 2em;
		}

		.Game-confirmBtn,
		.Game-newGameBtn {
			position: absolute;
			right: 1em;
			top: 42em;
			width: 21.78em;
			height: 3.61em;
		}
		.Game-selectedActionsCounter {
			position: absolute;
			top: 43em;
			left: calc(50% - 5em);
			width: 10em;

		}
		.Game-actionOptions,
		.Game-areaPages {
			position: absolute;
			width: 56em;
			left: calc(50% - 0.5 * 56em);
			margin-top: 2em;
			
		}
		.Game-areaPages {
			position: relative;
			height: 34em;
		}
		.Game-areaActions {
			position: absolute;
			top: 48em;
			left: 0;
			width: 100%;
			pointer-events: none;
		}
		}
}
