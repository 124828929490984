@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Stat {
	width: 100%;
	height: 100%;	
	white-space: nowrap;
	&.number {
		@include flex('flex-end', 'center');
		padding-right: 0.2em;
		background-color: #C6DFED;
		border: 0.2em solid #C6DFED;
		border-radius: 0.33em;

		span {
			font-size: 1.17em;
			font-weight: 800;
			color: $blueDark;
		}
	}
	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
		&:hover {
			background-color: white;
		}
	}

	&.affected {
		border-color: $blue;
		background-color: white;
	}

	&.animated {
		@include backgroundBlink(0.5s, 0s, 2);
	}


	&.range {
		padding: 0.25em 0;
		.Stat-track {
			position: relative;
			width: 100%;
			height: 100%;
			border-radius: 0.56em;
			border: 0.11em solid $blueDark;
			background-color: $blueDark;
			.Stat-indicator {
				@include transition('width', 0.5s, 'ease');
				position: relative;
				height: 100%;
				background-color: #E8B300;
				border-radius: 0.56em;
				@include box-shadow-inset(0, -0.22em, 0.17em, 0, rgba(black, 0.16));
				&::after {
					content: '';
					position: absolute;
					left: 6%;
					top: 0.17em;
					width: 88%;
					height: 0.33em;
					border-radius: 0.17em;
					background-color: #FAD456;
				}
			}
			.Stat-value {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include flex('center', 'center');
				span {
					font-weight: 800;
					color: white;
				}
			}
		}
	}
}