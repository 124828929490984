@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SelectedActionsCounter {
	width: 100%;
	height: 3.61em;
	border-radius: 1.83em;
	background-image: linear-gradient(#47B5F0, #207FB2);
	border: 0.11em solid $blueDarkest;
	@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
	@include flex('center', 'flex-start');
	.SelectedActionsCounter-wrap {
		width: 10em;
		height: 3em;
		background-image: linear-gradient($blue, #7DCCF7);
		border: 0.22em solid #7DCCF7;
		border-radius: 1.5em;
		padding: 0 0.5em;
		@include flex('space-between', 'center');
		.SelectedActionsCounter-action {
			position: relative;
			@include flex('center', 'center');
			width: 1.78em;
			height: 1.78em;
			border-radius: 100%;
			background-color: #2F94CA;
			@include box-shadow-inset-outset(
				0, 0.28em, 0.11em, 0, rgba(black, 0.16),
				0, 0.11em, 0, 0, #7DCCF7
			);

			.SelectedActionsCounter-checkMark {
				position: absolute;
				left: calc(50% - 5em / 2 + 0.1em);
				width: 5em;
				height: 5em;

				&.selected {
					@include imageGrowIn();
				}
				&.unselected {
					@include transition('opacity', 0.25s, ease);
					left: calc(50% - 1.9em / 2 + 0.1em);
					width: 1.9em;
					height: 1.9em;
					@include opacity(0);
				}
				background-image: url('../../../assets/images/icons/icon-check.svg'), radial-gradient(50% 50%, rgba(white, 1) 30%, rgba(white, 0.75), rgba(white, 0));
			}
		}
	}
}