@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaActionOptionBox {
	position: relative;
	width: 17.5em;
	margin-bottom: 0.8em;
	border-radius: 0.8em;
	background-color: #E1F1F9;
	padding: 0.5em;
	margin-left: 0.4em;
	margin-right: 0.4em;
	cursor: pointer;
	@include opacity(0.75);
	@include flex('space-between','','column');
	overflow-x: hidden;
	border-style: solid;
	border-color: #3FB3F2;
	border-width: 0.3em;
	
	&:hover {
		@include opacity(1);
	}

	.AreaActionOptionBox-optionStatus {
		position: absolute;
		@include flex('center', 'center');
		top: 0.4em;
		right: 0.4em;
		width: 1.78em;
		height: 1.78em;
		border-radius: 100%;
		background-color: #C6DFED;
		@include box-shadow-inset-outset(
			0, 0.28em, 0.11em, 0, rgba(black, 0.16),
			0, 0.11em, 0, 0, #ffffff
		);

		.AreaActionOptionBox-checkMark {
			position: absolute;
			left: calc(50% - 5em / 2 + 0.1em);
			width: 5em;
			height: 5em;

			&.confirmed {
				@include imageGrowIn();
			}
			&.unconfirmed {
				@include transition('opacity', 0.25s, ease);
				left: calc(50% - 1.9em / 2 + 0.1em);
				width: 1.9em;
				height: 1.9em;
				@include opacity(0);
			}
			background-image: url('../../../assets/images/icons/icon-check.svg'), radial-gradient(50% 50%, rgba(white, 1) 30%, rgba(white, 0.75), rgba(white, 0));
		}
	}

	.AreaActionOptionBox-optionTitle {
		span {
			color: $blueDark;
			font-size: 1.4em;
			font-weight: bolder;
		}
	}

	.AreaActionOptionBox-optionDescription {
		height: 2em;
		margin-bottom: 0.5em;
		line-height: 1.1em;
		span {
			color: $blueDark;
			font-size: 1em;
			font-weight: bold;
		}
	}

	.AreaActionOptionBox-content {
		position: relative;
		background-color: #C5DFEE;
		width: 100%;
		height: 10.2em;
		border-radius: 0.3em;

		.AreaActionOptionBox-image {
			background-size: contain;
			width: 100%;
			height: 100%;
			&.friend_01 {
				background-image: url(../../../assets/images/designs/friend_01.png);
			}
			&.friend_02 {
				background-image: url(../../../assets/images/designs/friend_02.png);
			}
			&.graffiti_01 {
				background-image: url(../../../assets/images/designs/graffiti_01.png);
			}
			&.graffiti_02 {
				background-image: url(../../../assets/images/designs/graffiti_02.png);
			}
			&.gueststar_01 {
				background-image: url(../../../assets/images/designs/gueststar_01.png);
			}
			&.horrorart_01 {
				background-image: url(../../../assets/images/designs/horrorart_01.png);
			}
			&.horrorart_02 {
				background-image: url(../../../assets/images/designs/horrorart_02.png);
			}
			&.horrorart_03 {
				background-image: url(../../../assets/images/designs/horrorart_03.png);
			}
			&.indie_01 {
				background-image: url(../../../assets/images/designs/indieband_01.png);
			}
			&.indie_02 {
				background-image: url(../../../assets/images/designs/indieband_02.png);
			}
			&.indie_03 {
				background-image: url(../../../assets/images/designs/indieband_03.png);
			}
			&.indie_04 {
				background-image: url(../../../assets/images/designs/indieband_04.png);
			}
			&.kawaii_01 {
				background-image: url(../../../assets/images/designs/kawaii_01.png);
			}
			&.kawaii_02 {
				background-image: url(../../../assets/images/designs/kawaii_02.png);
			}
			&.kawaii_03 {
				background-image: url(../../../assets/images/designs/kawaii_03.png);
			}
			&.kawaii_04 {
				background-image: url(../../../assets/images/designs/kawaii_04.png);
			}
			&.kawaii_05 {
				background-image: url(../../../assets/images/designs/kawaii_05.png);
			}
			&.metalband_01 {
				background-image: url(../../../assets/images/designs/metalband_01.png);
			}
			&.metalband_02 {
				background-image: url(../../../assets/images/designs/metalband_02.png);
			}
			&.metalband_03 {
				background-image: url(../../../assets/images/designs/metalband_03.png);
			}
			&.psychedelic_01 {
				background-image: url(../../../assets/images/designs/psychedelic_01.png);
			}
			&.psychedelic_02 {
				background-image: url(../../../assets/images/designs/psychedelic_02.png);
			}
			&.psychedelic_03 {
				background-image: url(../../../assets/images/designs/psychedelic_03.png);
			}
			&.stjernekunst_01 {
				background-image: url(../../../assets/images/designs/stjernekunst_01.png);
			}
			&.streetart_01 {
				background-image: url(../../../assets/images/designs/streetart_01.png);
			}
			&.streetart_02 {
				background-image: url(../../../assets/images/designs/streetart_02.png);
			}
			&.streetart_03 {
				background-image: url(../../../assets/images/designs/streetart_03.png);
			}
		}

		.AreaActionOptionBox-price {
			position: absolute;
			top: 0;
			right: 0;
			min-width: 7em;
			height: 2.44em;
			border-radius: 0.56em;
			background-color: #3FB3F2;
			padding: 0 0.61em; 
			@include flex('flex-end', 'center');
			span {
				color: white;
				font-size: 1.44em;
				font-weight: 800;
			}
		}
	}

	&.selected {
		background-color: white;
		border-color: #E8B300;
		@include opacity(1);
		.AreaActionOptionBox-content {
			background-color: $blueLightest;
			.AreaActionOptionBox-price {
				background-color: #E9B400;
			}
		}
	}
}