@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupVideo {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#062B33, 0.2);
	backdrop-filter: blur(1px);
	z-index: 20;
	@include flex('center', 'center');

	.PopupVideo-content {
		position: relative;
		background-color: #E1F1F9;
		border-radius: 0.78em;
		border: solid 0.33em #46B4EF;
		padding: 1.75em 5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba(black, 0.2));

		.PopupVideo-closeBtn {
			cursor: pointer;
			position: absolute;
			right: 0.5em;
			top: 0.5em;
			width: 1.75em;
			height: 1.75em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
		}

		.PopupVideo-videoWrap {
			width: 84.67em;
			height: calc(0.5625 * 84.67em);
			background-image: url('../../../assets/images/video-thumb.jpg');
			.PopupVideo-video {
				width: 100%;
				height: 100%;
				&.loading,
				&.ready,
				&.ended {
					@include opacity(0);
				}
				
			}
			.PopupVideo-videoPlayBtn {
				position: absolute;
				top: calc(50% - 4em);
				left: calc(50% - 4em);
				width: 8em;
				height: 8em;
				background-image: url('../../../assets/images/icons/icon-play.svg');
				cursor: pointer;	
				&.loading {
					cursor: default;
					background-image: url('../../../assets/images/icons/icon-loading.svg');
				}
				&.playing {
					display: none;
				}	
			}
		}
		
		
	}
}