@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.RoundNumber {
	width: 21.78em;
	padding: 0.86em 0.5em 0em 0.5em;
	@include flex('space-between', 'center');
	
	.RoundNumber-line {
		position: absolute;
		width: 0;
		height: 0.22em;
		background-color: #5B6F79;

		&.blue {
			left: 1.45em;
			background-color: #2793CC;
		}
		&.grey {
			right: 1.45em;
		}

		&.round-1 {
			&.blue {width: 0%;}
			&.grey {width: calc(100% - 2.9em);}
		}
		&.round-2 {
			&.blue {width: calc((100% - 2.9em) * 0.1429);}
			&.grey {width: calc(100% - 2.9em - (100% - 2.9em) * 0.1429);}
		}
		&.round-3 {
			&.blue {width: calc((100% - 2.9em) * 0.1429 * 2);}
			&.grey {width: calc(100% - 2.9em - (100% - 2.9em) * 0.1429 * 2);}
		}
		&.round-4 {
			&.blue {width: calc((100% - 2.9em) * 0.1429 * 3);}
			&.grey {width: calc(100% - 2.9em - (100% - 2.9em) * 0.1429 * 3);}
		}
		&.round-5 {
			&.blue {width: calc((100% - 2.9em) * 0.1429 * 4);}
			&.grey {width: calc(100% - 2.9em - (100% - 2.9em) * 0.1429 * 4);}
		}
		&.round-6 {
			&.blue {width: calc((100% - 2.9em) * 0.1429 * 5);}
			&.grey {width: calc(100% - 2.9em - (100% - 2.9em) * 0.1429 * 5);}
		}
		&.round-7 {
			&.blue {width: calc((100% - 2.9em) * 0.1429 * 6);}
			&.grey {width: calc(100% - 2.9em - (100% - 2.9em) * 0.1429 * 6);}
		}
		&.round-8,
		&.round-9 {
			&.blue {width: calc(100% - 2.9em);}
			&.grey {width: 0;}
		}
	}

	.RoundNumber-round {
		position: relative;
		width: 1.9em;
		height: 1.9em;
		padding-bottom: 0.2em;
		background-image: url('../../../assets/images/icons/icon-round-grey.svg');
		@include flex('center', 'center');
		span {
			font-size: 1.33em;
			font-weight: 700;
			color: white;
		}

		&.current {
			background-image: url('../../../assets/images/icons/icon-round-yellow.svg');
			span {
				font-weight: 800;
			}
		}
		&.completed {
			background-image: url('../../../assets/images/icons/icon-round-blue.svg');
		}


	}
	
}