@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaActionOptions {
	width: 100%;
	text-align: left;
	
	.AreaActionOptions-options {
		width: 100%;
		max-height: 29em;
		overflow: auto;
		@include custom-scrollbar(0.67em, rgba(#000000, 0.5), #ADBDC6);

		&.box {
			flex-wrap: wrap;
			@include flex('', '');
		}
	
		.AreaActionOptions-option {
			position: relative;
		}
	}
}