@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: 800;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		cursor: not-allowed;
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}	
	}

	&.blue {
		background-image: linear-gradient(#47B5F0, #207FB2);
		border: 0.1em solid $blueDarkest;
		border-radius: 0.78em;
		@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
		@include flex('center', 'flex-start');
		padding: 0 0.28em 0.61em 0.28em;
		span {
			width: 100%;
			height: 100%;
			font-size: 1.44em;
			font-weight: 800;
			color: white;
			background-image: linear-gradient($blue, #7DCCF7);
			border: 0.15em solid #7DCCF7;
			border-radius: 0.54em;
			@include flex('center', 'center');
		}

		&:hover {
			background-image: linear-gradient(darken(#47B5F0, 3%), darken(#207FB2, 3%));
			span {
				background-image: linear-gradient(darken($blue, 3%), darken(#7DCCF7, 3%));
			}
		}
	}

	&.yellow {
		background-image: linear-gradient($orangeDark, $brown);
		border: 0.1em solid #24221B;
		border-radius: 0.78em;
		@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
		@include flex('center', 'flex-start');
		padding: 0 0.28em 0.61em 0.28em;
		span {
			width: 100%;
			height: 100%;
			font-size: 1.44em;
			font-weight: 800;
			color: white;
			background-image: radial-gradient($orange, $orangeDark);
			border: 0.15em solid $orange;
			border-radius: 0.54em;
			@include flex('center', 'center');
		}

		&:hover {
			background-image: linear-gradient(darken($orangeDark, 3%), darken($brown, 3%));
			span {
				background-image: radial-gradient(darken($orange, 3%), darken($orangeDark, 3%));
			}
		}
	}

	&.grey {
		background-image: linear-gradient(#8BA1AD, #596D77);
		border: 0.1em solid #1E1E20;
		border-radius: 0.78em;
		@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
		@include flex('center', 'flex-start');
		padding: 0 0.28em 0.61em 0.28em;
		span {
			width: 100%;
			height: 100%;
			font-size: 1.44em;
			font-weight: 800;
			color: white;
			background-image: linear-gradient(#8BA1AD, #ADBDC6);
			border: 0.15em solid #ADBDC6;
			border-radius: 0.54em;
			@include flex('center', 'center');
		}

		&:hover {
			background-image: linear-gradient(darken(#8BA1AD, 3%), darken(#596D77, 3%));
			span {
				background-image: linear-gradient(darken(#8BA1AD, 3%), darken(#ADBDC6, 3%));
			}
		}
	}

	&.fullHeight {
		height: 3.61em;
	}

	&.start,
	&.video {
		width: 16.67em;
		height: 3.61em;
	}
	&.video {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-video.svg');
			background-size: 2em auto;
			background-position: left 1em top 0.5em;
		}	

	}
	&.navigation {
		width: 18.22em;
		height: 3.61em;
	}
	&.selectAction {
		width: 16.11em;
		height: 3.61em;
	}

	&.popup {
		width: 10em;
		height: 3.61em;	
	}

	&.pageNav {
		height: 5em;
		span {
			padding-top: 0.2em;
			@include flex('center', 'flex-start');
		}
	}
}