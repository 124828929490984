@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.StoreFront {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 60em;
	padding: 2em;
	overflow: hidden;
	@include flex('center', 'center');
	z-index: 5;
	background-color: #062B33;
	&.enterShop {
		background-color: transparent;
		.StoreFront-subtitle,
		.StoreFront-characters,
		.StoreFront-logos,
		.StoreFront-logo,
		.StoreFront-startBtn,
		.StoreFront-manualBtn,
		.StoreFront-videoBtn,
		.StoreFront-toggleCookiePopup,
		.StoreFront-roundAnimation {
			@include fadeOutAnimation(0.6s, 'ease-out');
		}
		.StoreFront-store {
			@include zoomInAndFadeOutAnimation(2s, 'linear', 0.8s);
		}
	}

	.StoreFront-subtitle {
		position: absolute;
		top: 11.25em;
		width: 20em;
		text-align: center;
		line-height: 1.08;
		margin-left: 5em;
		span {
			font-size: 1.44em;
			font-weight: 800;
			color: #E1F1F9;
			text-transform: uppercase;
		}
	}

	.StoreFront-store,
	.StoreFront-characters {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../../assets/images/backgrounds/background-splash-shop.png');
		background-size: auto 100%;
		background-position: top center;
		@include opacity(1);
		@include origin(50%, 50%);
	}
	.StoreFront-characters { 
		background-image: url('../../assets/images/backgrounds/background-splash-characters.png');
	}

	.StoreFront-logos {
		.StoreFront-logoGameLab {
			position: absolute;
			cursor: pointer;
			background-image: url('../../assets/images/gamelab-logo.svg');
			background-size: contain;
			width: 10em;
			height: 2em;
			top: 3.33em;
			right: 3.33em;
		}
		.StoreFront-fundLogo {
			position: absolute;
			background-image: url('../../assets/images/fund-logo.svg');
			background-size: contain;
			width: 15.56em;
			height: 3.7em;
			top: 3.33em;
			left: 3.33em;
		}
	}
	.StoreFront-logo {
		position: absolute;
		top: 4em;
		@include flex('center', 'center');
		@include opacity(1);
	}
	.StoreFront-startBtn {
		position: absolute;
		bottom: 7em;
		@include opacity(1);
	}
	.StoreFront-manualBtn {
		position: absolute;
		bottom: 12em;
		left: calc(50% + 33.22em);
		@include opacity(1);		
	}
	.StoreFront-videoBtn {
		position: absolute;
		bottom: 7em;
		left: calc(50% + 33.22em);
		@include opacity(1);
	}
	.StoreFront-toggleCookiePopup {
		position: absolute;
		left: 2em;
		bottom: 2em;
		width: 3em;
		height: 3em;
		background-image: url('../../assets/images/icons/icon-cookie.svg');
		cursor: pointer;
		@include opacity(1);
	}

	.StoreFront-roundAnimation {
		position: relative;
		width: 50em;
		background-color: #E1F1F9;
		border-radius: 0.78em;
		border: solid 0.33em #46B4EF;
		padding: 2em 0;
		text-align: center;
		.StoreFront-rounds {
			position: relative;
			display: inline-block;
			.StoreFront-line {
				position: absolute;
				top: calc(0.5 * 1.94em);
				left: calc(0.5 * 5.5em);
				width: calc(100% - 5.5em);
				height: 0.22em;
				background-color: #46B4EF;
			}
			.StoreFront-round {
				position: relative;
				display: inline-block;
				vertical-align: top;
				padding-top: 2.33em;
				width: 5.5em;
				text-align: center;
				span {
					color: #265684;
					font-size: 1.1em;
					font-weight: 700;
				}
				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: calc(50% - (0.5 * 1.78em));
					width: 1.78em;
					height: 1.94em;
					border-radius: 100%;
					background-image: url('../../assets/images/icons/icon-round-future.svg');
				}
				&.completed {
					&::after {
						background-image: url('../../assets/images/icons/icon-round-past.svg');
					}
				}
				&.new {
					&::after {
						background-image: url('../../assets/images/icons/icon-round-current.svg');
					}
					span {
						font-weight: 800;
					}
				}
			}
		}


		.StoreFront-text {
			position: relative;
			width: 33.33em;
			margin: 1em auto;
			p {
				margin: 0;
				color: #265684;
				font-weight: 700;
				font-size: 1.33em;
				text-align: center;
			}
		}

		.StoreFront-animation {
			position: relative;
			width: 35.11em;
			height: 13.72em;
			margin: auto;
			background-image: url('../../assets/images/animations/between-rounds.gif');
		}
		.StoreFront-timer {
			width: 18.77em;
			height: 1.11em;
			margin: 1em auto 0 auto;
			border-radius: 0.56em;
			border: 0.11em solid $blueDark;
			background-color: $blueDark;
			.StoreFront-track {
				position: relative;
				width: 0;
				height: 100%;
				background-color: #E8B300;
				border-radius: 0.56em;
				-webkit-animation: timerTrack var(--duration) linear 0s 1 forwards;
				animation: timerTrack var(--duration) linear 0s 1 forwards;
				@include box-shadow-inset(0, -0.22em, 0.17em, 0, rgba(black, 0.16));
				&::after {
					content: '';
					position: absolute;
					left: 0.22em;
					top: 0.17em;
					width: calc(100% - 0.44em);
					height: 0.33em;
					border-radius: 0.17em;
					background-color: #FAD456;
				}
			}
		}
	}
}

@-webkit-keyframes timerTrack {
	0% {width: 0;}
	100% {width: 100%;}
}
@keyframes timerTrack {
	0% {width: 0;}
	100% {width: 100%;}
}