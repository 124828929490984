@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.BudgetStat {
	@include flex('flex-end', 'center');
	width: 7.84em;
	height: 1.78em;
	padding-right: 0.2em;
	background-color: #C6DFED;
	border: 0.2em solid #C6DFED;
	border-radius: 0.33em;
	white-space: nowrap;
	span {
		font-size: 1.17em;
		font-weight: 800;
		color: $blueDark;
	}

	&.animated {
		@include backgroundBlink(0.5s, 0s, 2,);
	}

	&.affected {
		border-color: $orange;
		background-color: white;
	}

	&.negative {
		background-color: #F6ACBB;
		border-color: #F6ACBB;
		&.animated {
			@include backgroundBlink2(0.5s, 0s, 2);
		}
		&.affected {
			border-color: #E6163F;
			background-color: white;
		}
	}
}