@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.AreaPageReport {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1em;
	p {
		font-size: 1.33em;
		font-weight: 700;
		color: $blueDark;

	}
}