@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#062B33, 0.2);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');
	&.warning {
		z-index: 10;
	}
	&.warning2 {
		z-index: 10;
		.Popup-content {
			padding: 2em 3em;
			text-align: left;
		}
	}
	.Popup-content {
		position: relative;
		width: 37.3em;
		background-color: white;
		border-radius: 0.78em;
		border: solid 0.33em $blue;
		padding: 2em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba(black, 0.2));

		.Popup-closeButton {
			cursor: pointer;
			position: absolute;
			right: 1em;
			top: 1em;
			width: 2.66em;
			height: 2.66em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
		}

		.Popup-title {
			color: $blueDark;
			margin-bottom: 2.2em;
			span {
				font-size: 2.8em;
				text-transform: capitalize;
			}
		}
		.Popup-text {
			margin-bottom: 3.2em;
			p {
				color: $blueDark;
				font-size: 1.4em;
				line-height: 1.33;
				&:nth-child(1) {margin-top: 0;}
			}
		}
		.Popup-buttons {
			width: 100%;
			> div {margin: 0 1em;}
			@include flex('center', 'center');
			// &.single {
			// }
		}
	}
}