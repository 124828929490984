@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Graph {
	position: relative;
	width: 100%;
	height: 100%;
	svg {
		font-size: 1em;
	}
	.Graph-grid {
		line {
			stroke: #C6DFED;
			stroke-width: 0.11em;
		}
	}
	.Graph-topLine {
		stroke: #C6DFED;
		stroke-width: 0.11em;
	}
	.Graph-axis {
		> line {
			stroke: #C6DFED;
			stroke-width: 0.11em;
		}
		.Graph-tic {
			&.hidden {display: none;}
			text {
				font-family: inherit;
				font-size: 1em;
				font-weight: 700;
				fill: $blueDark;
			}
			&.bottom {transform: translate(-3em, 0.2em);}
			&.left {transform: translate(-0.2em, 0);}
		}
	}
	.Graph-line {
		stroke-width: 0.22em;
		&.turnover,
		&.predicted-turnover {
			stroke: $orangeDark;
			
		}
		&.expenses,
		&.predicted-expenses {
			stroke: #E34C48;
		}
		&.profit,
		&.predicted-profit {
			stroke: #2AAF39;
		}
		&.predicted-turnover,
		&.predicted-expenses,
		&.predicted-profit {
			stroke-dasharray: 0.5em;
		}
	}
	.Graph-rect {
		fill: transparent;
	}
	.Graph-marker {
		&.turnover {
			fill: $orangeDark;
		}
		&.expenses {
			fill: #E34C48;
		}
		&.profit {
			fill: #2AAF39;
		}
	}

	.Graph-labelIcon {
		stroke: 0;
		transform: translate(0em, -0.25em);
		&.turnover {
			fill: $orangeDark;
		}
		&.expenses {
			fill: #E34C48;
		}
		&.profit {
			fill: #2AAF39;
		}
	}
	.Graph-labelText {
		position: relative;
		font-size: 0.8em;
		fill: $blueDark;
	}


	.Graph-toolTip {
		position: relative;
		width: 6em;
		height: 2em;
		color: #3E57AA;
		background-color: white;
		border-radius: 0.44em;
		padding: 0 0.5em;
		transform: translate(-3em, -2.75em);
		opacity: 0;
		@include fadeIn();
		@include flex('center', 'center');
		@include box-shadow(0, 3px, 6px, 0, rgba(black, 0.16));
		span {
			display: block;
			font-size: 0.75em;
			&:nth-of-type(1) {
				font-weight: bold;
			}
		}
		&.demand2,
		&.unitcost2 {
			width: 10em;
			transform: translate(-4em, -2.75em);
		}
		&.demand2 {
			transform: translate(-3.25em, -2.75em);
		}
		&:after {
			content: '';
			position: absolute;
			left: calc(50% - 0.5em);
			top: calc(100% - 1px);
			width: 0;
			height: 0;
			border-top: 0.5em solid white;
			border-left: 0.5em solid transparent;
			border-right: 0.5em solid transparent;
		}
	}
}