@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.Background-location,
	.Background-characters {
		position: absolute;
		top: 0;
		left: 0;
		// left: calc(50% - 0.5 * 106.67em);
		width: 100%; // 106.67em;
		height: 60em;
		background-size: auto 100%;
		background-position: top center;
		@include backgroundTransition(transform, 0.6s, ease-out, opacity, 0.6s, ease-out);
		pointer-events: none;
	}

	.Background-location {
		&.shop {
			transition-delay: 0.6s;
			background-image: url('../../../assets/images/backgrounds/background-shop.png');
		}
		&.production {
			@include opacity(0);
			@include translate(50vw);
			background-image: url('../../../assets/images/backgrounds/background-production.png');
		}
		&.office {
			@include opacity(0);
			@include translate(-50vw);
			background-image: url('../../../assets/images/backgrounds/background-office.png');
		}
	}
	.Background-characters {
		&.shop {
			transition-delay: 0.6s;
			background-image: url('../../../assets/images/backgrounds/background-shop-character.png');
		}
		&.production {
			@include opacity(0);
			@include translate(-50vw);
			background-image: url('../../../assets/images/backgrounds/background-production-character.png');
		}
		&.office {
			@include opacity(0);
			@include translate(50vw);
			background-image: url('../../../assets/images/backgrounds/background-office-character.png');
		}
	}

	.Background-design {
		position: absolute;
		width: 7.78em;
		height: 5.08em;
		@include opacity(0.8);
		&.shop-1, &.shop-2, &.shop-3 {
			top: 34.78em;
			background-size: 90% auto;
		}
		&.shop-1 {
			left: calc(50% - 15em);
		}
		&.shop-2 {
			left: calc(50% - 5em);
		}
		&.shop-3 {
			left: calc(50% + 4.6em);
		}
		&.production-1, &.production-2, &.production-3 {
			background-size: 100% auto;
			top: 12.8em;
		}
		&.production-1 {
			left: calc(50% - 13.4em);
		}
		&.production-2 {
			left: calc(50% - 0.5em);
		}
		&.production-3 {
			left: calc(50% + 12.1em);
		}
		&.office-1 {
			background-size: 100% auto;
			top: 35.5em;
			left: calc(50% - 44em);
		}
		&.start_01 {
			background-image: url(../../../assets/images/designs/start_01.png);
			background-size: 90% auto;
		}
		&.friend_01 {
			background-image: url(../../../assets/images/designs/friend_01.png);
			background-size: 85% auto;
		}
		&.friend_02 {
			background-image: url(../../../assets/images/designs/friend_02.png);
			background-size: 100% auto;
		}
		&.graffiti_01 {
			background-image: url(../../../assets/images/designs/graffiti_01.png);
		}
		&.graffiti_02 {
			background-image: url(../../../assets/images/designs/graffiti_02.png);
		}
		&.gueststar_01 {
			background-image: url(../../../assets/images/designs/gueststar_01.png);
			background-size: 95% auto;
		}
		&.horrorart_01 {
			background-image: url(../../../assets/images/designs/horrorart_01.png);
			background-size: 95% auto;
		}
		&.horrorart_02 {
			background-image: url(../../../assets/images/designs/horrorart_02.png);
			background-size: 100% auto;
		}
		&.horrorart_03 {
			background-image: url(../../../assets/images/designs/horrorart_03.png);
			background-size: 100% auto;
		}
		&.indie_01 {
			background-image: url(../../../assets/images/designs/indieband_01.png);
		}
		&.indie_02 {
			background-image: url(../../../assets/images/designs/indieband_02.png);
		}
		&.indie_03 {
			background-image: url(../../../assets/images/designs/indieband_03.png);
		}
		&.indie_04 {
			background-image: url(../../../assets/images/designs/indieband_04.png);
		}
		&.kawaii_01 {
			background-image: url(../../../assets/images/designs/kawaii_01.png);
			background-size: 100% auto;
		}
		&.kawaii_02 {
			background-image: url(../../../assets/images/designs/kawaii_02.png);
			background-size: 100% auto;
		}
		&.kawaii_03 {
			background-image: url(../../../assets/images/designs/kawaii_03.png);
			background-size: 100% auto;
		}
		&.kawaii_04 {
			background-image: url(../../../assets/images/designs/kawaii_04.png);
			background-size: 95% auto;
		}
		&.kawaii_05 {
			background-image: url(../../../assets/images/designs/kawaii_05.png);
			background-size: 100% auto;
		}
		&.metalband_01 {
			background-image: url(../../../assets/images/designs/metalband_01.png);
		}
		&.metalband_02 {
			background-image: url(../../../assets/images/designs/metalband_02.png);
			background-size: 95% auto;
		}
		&.metalband_03 {
			background-image: url(../../../assets/images/designs/metalband_03.png);
			background-size: 95% auto;
		}
		&.psychedelic_01 {
			background-image: url(../../../assets/images/designs/psychedelic_01.png);
			background-size: 100% auto;
		}
		&.psychedelic_02 {
			background-image: url(../../../assets/images/designs/psychedelic_02.png);
			background-size: 95% auto;
		}
		&.psychedelic_03 {
			background-image: url(../../../assets/images/designs/psychedelic_03.png);
			background-size: 100% auto;
		}
		&.stjernekunst_01 {
			background-image: url(../../../assets/images/designs/stjernekunst_01.png);
			background-size: 100% auto;
		}
		&.streetart_01 {
			background-image: url(../../../assets/images/designs/streetart_01.png);
			background-size: 100% auto;
		}
		&.streetart_02 {
			background-image: url(../../../assets/images/designs/streetart_02.png);
			background-size: 95% auto;
		}
		&.streetart_03 {
			background-image: url(../../../assets/images/designs/streetart_03.png);
			background-size: 95% auto;
		}
	}

	&:not(.shop) {
		.Background-location {
			&.shop {
				transition-delay: 0s;
				@include opacity(0);
				@include translate(-50vw);
			}
		}
		.Background-characters {
			&.shop {
				transition-delay: 0s;
				@include opacity(0);
				@include translate(50vw);
			}
		}
	}
	
	&.production {
		.Background-location {
				&.production {
					transition-delay: 0.6s;
					@include opacity(1);
					@include translate(0);
				}
		}
		.Background-characters {
			&.production {
				transition-delay: 0.6s;
				@include opacity(1);
				@include translate(0);
			}
		}
	}
	&.office {
		.Background-location {
				&.office {
					transition-delay: 0.6s;
					@include opacity(1);
					@include translate(0);
				}
		}
		.Background-characters {
			&.office {
				transition-delay: 0.6s;
				@include opacity(1);
				@include translate(0);
			}
		}
	}
}