@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaPages {
	width: 100%;
	height: 100%;
	text-align: left;
	.AreaPages-nav {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		@include flex('flex-start', 'flex-start');
		.AreaPages-navItem {
			width: 15.17em;
			margin-right: 0.6em;
		}
	}

	.AreaPages-page {
		position: relative;
		width: 100%;
		height: 100%;
		min-height: 20em;
		border: 1.5em solid transparent;
		border-top-width: 4.5em;
		border-bottom-width: 2em;
		border-image-source: url('../../../assets/images/backgrounds/background-page.svg');
		border-image-slice: 81 27 36 27 fill;
		border-image-width: 4.5em 1.5em 2em 1.5em;
		pointer-events: none;

		.AreaPages-pageTitle {
			position: absolute;
			top: -4.5em;
			left: -2em;
			width: 15.17em;
			height: 3.25em;
			@include flex('center', 'center');
			span {
				font-size: 1.44em;
				font-weight: 800;
				color: white;
				@include flex('center', 'center');
			}
		}

		.AreaPages-closeBtn {
			position: absolute;
			top: -0.3em;
			right: -0.2em;
			width: 2.83em;
			height: 2.83em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
			background-size: 2.33em auto;
			pointer-events: all;
			cursor: pointer;
			&:hover {
				background-size: 2.5em auto;
			}
		}

		&.index-1 {
			border-image-source: url('../../../assets/images/backgrounds/background-page-1.svg');
			.AreaPages-pageTitle {
				left: calc(-2em + 15.17em + 1.1em);
			}
		}
		&.index-2 {
			border-image-source: url('../../../assets/images/backgrounds/background-page-2.svg');
			.AreaPages-pageTitle {
				left: calc(-2em + 2 * 15.17em + 1.1em + 0.6em);
			}
		}
	}
}