@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AreaNavigation {
	position: relative;
	width: 56em;
	margin: 0 auto;
	pointer-events: all;
	@include flex('space-between', 'center');
}