@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Budget {
	width: 21.78em;
	background-color: $brown;
	border: 0.1em solid #24221B;
	border-radius: 0.78em;
	padding: 0 0.28em 0.61em 0em;
	@include box-shadow(0, 0.33em, 0.33em, 0, rgba(black, 0.5));
	@include flex('center', 'flex-start');
	@include transition('margin', 0.2s, 'ease-in-out');
	&.expanded {
		margin-bottom: -3.5em;
		.Budget-content .Budget-expandBtn {
			background-image: url('../../../assets/images/icons/icon-minus.svg');
		}
	}
	.Budget-content {
		width: 100%;
		border-radius: 0.78em;
		background-image: radial-gradient($orange, $orangeDark);
		border: 0.15em solid $orange;
		border-radius: 0.54em;
		padding: 0.44em;
		text-align: left;

		.Budget-title {
			padding-left: 0.56em;
			span {
				font-size: 1.44em;
				font-weight: 800;
				color: white;
			}
		}

		.Budget-expandBtn {
			position: absolute;
			right: 1em;
			top: 0.6em;
			width: 2em;
			height: 2em;
			background-image: url('../../../assets/images/icons/icon-plus.svg');
			cursor: pointer;
		}

		.Budget-group {
			background-color: $blueLightest;
			border-radius: 0.44em;
			margin: 0.44em 0;
			padding: 0.67em;
			.Budget-stat {
				@include flex('space-between', 'center');
				margin-bottom: 0.33em;
				height: 1.8em;
				@include transition('height', 0.2s, 'ease-in-out');
				&.hidden {
					height: 0;
					overflow: hidden;
					padding: 0;
					margin: 0;
				}
				.Budget-statLabel {
					width: 11.22em;
					
					span {
						font-size: 1.17em;
						font-weight: 800;
						color: $blueDark;
					}

					
				}

				&.dictionaryEntry {
					.Budget-statLabel {
						cursor: pointer;
						background-image: url(../../../assets/images/icons/icon-info.svg);
						background-position: right 0.4em bottom calc(50%);
						background-size: 0.8em 0.8em;
						&:hover {
							background-color: $white;
							border-radius: 0.3em;
						}
					}
				}

				&.fixed-expenses,
				&.variable-expenses {
					.Budget-statLabel,
					.Budget-statValue {
						span {
							font-size: 1em;
							font-weight: 800; 
						}
					}
				}
				&.expanded {
					.Budget-statLabel,
					.Budget-statValue {
						span {
							font-size: 0.9em;
							font-weight: 600; 
						}
					}
				}
			}
		}
	}

	&.lastQuarter {
		background-image: radial-gradient(#7DCCF7, $blue);
		border: none;
		@include no-box-shadow();
		.Budget-content {
			background: none;
			border: none;
		}
	}
}